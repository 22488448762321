import { Injectable } from "@angular/core";
import { ApiService } from "../api.service";
import { take } from "rxjs";
import { IExam } from "../../models/exam/exam.model";
import { setExams } from "../../ngrx/actions/exam.actions";
import { Store } from "@ngrx/store";
import { ExamState } from "../../ngrx/reducers/exam.reducer";

@Injectable()
export class ExamService {
  private readonly apiPrefix = '/client/exams';

  constructor(
    private api: ApiService,
    private store: Store<ExamState>,
  ) {
  }

  getExams() {
    return this.api.get(`${this.apiPrefix}`);
  }

  loadExams() {
    this.getExams().pipe(take(1))
      .subscribe(r => {
        const exams = r.data as IExam[];
        this.store.dispatch(setExams(exams));
      });
  }

  getLevels() {
    return this.api.get(`${this.apiPrefix}/levels`);
  }

  getExamScores(examId: number, qs = '') {
    return this.api.get(`${this.apiPrefix}/${examId}/scores${qs}`);
  }

  getExamScore(examId: number, id: number) {
    return this.api.get(`${this.apiPrefix}/${examId}/scores/${id}`);
  }

  getExamLessons(examId: number) {
    return this.api.get(`${this.apiPrefix}/${examId}/lessons`);
  }

  getExamLessonTopics(examId: number, lessonId: number) {
    return this.api.get(`${this.apiPrefix}/${examId}/lessons/${lessonId}/topics`);
  }

  getExamLessonGroups(examId: number) {
    return this.api.get(`${this.apiPrefix}/${examId}/lesson-groups`);
  }

  createExamScore(examId: number, data: object) {
    return this.api.post(`${this.apiPrefix}/${examId}/scores`, data)
  }

  updateExamScore(examId: number, scoreId: number, data: object) {
    return this.api.patch(`${this.apiPrefix}/${examId}/scores/${scoreId}`, data)
  }

  deleteExamScore(examId: number, scoreId: number) {
    return this.api.delete(`${this.apiPrefix}/${examId}/scores/${scoreId}`);
  }

  createUploadRequest(examId: number, scoreId: number, data: object) {
    return this.api.post(`${this.apiPrefix}/${examId}/scores/${scoreId}/result-file`, data);
  }

  saveUploadRequest(examId: number, scoreId: number, requestId: string) {
    return this.api.patch(`${this.apiPrefix}/${examId}/scores/${scoreId}/result-file/${requestId}`, {});
  }

  getExamScoreFile(examId: number, scoreId: number) {
    return this.api.get(`${this.apiPrefix}/${examId}/scores/${scoreId}/result-file`);
  }

  getExamMonthlyAverageScores(examId: number) {
    return this.api.get(`${this.apiPrefix}/${examId}/monthly-average-scores`);
  }

  getExamMonthlyLatestScores(examId: number, qs: string) {
    return this.api.get(`${this.apiPrefix}/${examId}/monthly-latest-scores${qs}`);
  }
}
